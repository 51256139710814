import classNames from 'classnames';
import type { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Avatar } from '../../components/Avatar';
import { BulletSeparator } from '../../components/BulletSeparator';
import { Button } from '../../components/Button/Button';
import { IconClose } from '../../components/icons/Icons';
import { Link } from '../../components/Link';
import { useProfile } from '../../hooks/useProfile';
import { isMobile } from '../../utils/web';
import type { RequestFriendCallback } from '../api/relation';
import { profileInfosMessages } from './messages';
import { GradeBadge } from './ProfileData/GradeBadge';
import { StatusText } from './ProfileData/StatusText';
import { ProfilePodiumRewards } from './ProfilePodiumRewards';
import { ProfileRelationActions } from './ProfileRelations/ProfileRelationActions';

const messages = defineMessages({
  goToProfilePage: { defaultMessage: 'Voir le profil complet', id: 'KtGw+K' },
});

const FooterItem = ({ children }: { children: ReactNode }) => (
  <span className="prose-xs font-bold uppercase text-gray-450 font-source tracking-4">
    {children}
  </span>
);

/**
 *
 * @param userId
 * @param controller
 * @constructor
 */
export const ProfilePopoverContent = ({
  userId,
  controller,
  inviteCallback,
  noCloseButton,
}: {
  userId?: string;
  controller?: { hide: () => void };
  inviteCallback?: RequestFriendCallback;
  noCloseButton?: boolean;
}) => {
  const { profile } = useProfile({ userId });
  const currentUserId = profile?.user?.id as string;

  if (!profile) return null;

  const isMob = isMobile();

  return (
    <div className="ProfilePopoverContent relative rounded-lg md:min-w-[430px]">
      {!isMob && !noCloseButton && (
        <div className="absolute top-0 right-0">
          <Button variant="icon" className="no-underline" onClick={controller?.hide}>
            <IconClose />
          </Button>
        </div>
      )}

      <div className="flex justify-center gap-4 pl-3 py-3">
        <div className="flex flex-col items-center gap-2">
          <Avatar profile={profile} picture={profile.picture} size="vbig" legacyImage />
          <ProfilePodiumRewards profile={profile} />
        </div>

        <div className="flex flex-col space-y-[6px] flex-1 min-w-0">
          <div className={classNames('flex items-center space-x-2 flex-wrap', !isMob && 'mr-7')}>
            <h2 className="font-roboto capitalize prose-bigger text-black truncate">
              {profile?.pseudo}
            </h2>
            <GradeBadge profile={profile} />
            <ProfileRelationActions
              currentUserId={currentUserId}
              relationId={profile.relation?.id as string}
              status={profile.relation?.status as string}
              profile={profile}
              relation={profile?.relation}
              inviteCallback={inviteCallback}
              handleClose={controller?.hide}
              hideRemoveFriendButton
            />
          </div>

          <StatusText profile={profile} className="pr-4" />

          {/* The wireframes don't include the twitter username here. But let's keep the code in case we want to restore it. */}
          {/* {profile.twitterUsername && (
            <div className="flex items-center my-1 text-secondary-base prose-info">
              <TwitterBirdIcon />
              <span className="text-base ml-2">{profile.twitterUsername}</span>
            </div>
          )} */}

          <div className="prose-sub text-gray-medium my-1 pr-4">
            <FormattedMessage
              {...profileInfosMessages.inscriptionDate}
              values={{ createdAt: new Date(profile.createdAt ?? 0) }}
            />
          </div>

          {profile?.bio ? (
            <div
              className="my-1 italic prose overflow-y-auto max-h-50 text-black pr-4 [word-break:break-word]"
              dangerouslySetInnerHTML={{ __html: profile?.bio }}
            />
          ) : (
            <div className="my-1 italic prose overflow-y-auto max-h-50 text-black pr-4">
              <FormattedMessage {...profileInfosMessages.missingBio} />
            </div>
          )}
        </div>
      </div>

      <div className="px-3 pt-5 pb-3 flex items-baseline justify-center gap-2 text-center">
        <Link to="profile" params={{ pseudo: profile.pseudo, tab: 'pages' }}>
          <a>
            <FooterItem>
              <FormattedMessage
                {...profileInfosMessages.messagesCount}
                values={{ count: profile.meta?.countCommentAndMessage ?? 0 }}
              />
            </FooterItem>
          </a>
        </Link>
        <BulletSeparator />
        <FooterItem>
          <FormattedMessage
            {...profileInfosMessages.voteSendedCount}
            values={{ count: profile.meta?.voteSended ?? 0 }}
          />
        </FooterItem>
        <BulletSeparator />
        <FooterItem>
          <FormattedMessage
            {...profileInfosMessages.voteReveivedCount}
            values={{ count: profile.meta?.voteReceived ?? 0 }}
          />
        </FooterItem>
      </div>

      <div className="border-t border-gray-lighter flex justify-center">
        {profile.pseudo && (
          <Link to="profile" params={{ pseudo: profile.pseudo }}>
            <a className="flex-1 rounded-sm rounded-t-none bg-gray-360 hover:bg-gray-400 p-2 outline-none focus:outline-none no-underline text-xs font-bold text-center text-white cursor-pointer">
              <FormattedMessage {...messages.goToProfilePage} />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};
