import classNames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';

import type { Profile } from '../../../types/api';

interface Props {
  profile: Profile | undefined;
  className?: string;
}

export const StatusText: FC<Props> = memo(function StatusText(props) {
  const { profile, className } = props;

  if (!profile?.proStatus) return null;
  return <span className={classNames('text-sm truncate', className)}>{profile.proStatus}</span>;
});
