import classNames from 'classnames';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { ReactComponent as IconMedalBronze } from '../../icons/icon-medal-bronze.svg';
import { ReactComponent as IconMedalGold } from '../../icons/icon-medal-gold.svg';
import { ReactComponent as IconMedalSilver } from '../../icons/icon-medal-silver.svg';
import type { Profile, Reward } from '../../types/api';
import { rewardTypeTextClassnames } from '../Rewards/Rewards';

const rewardColor = (type: keyof typeof rewardTypeTextClassnames) => {
  switch (type) {
    case 'copper':
      return 'text-yellow-400';
    case 'silver':
      return 'text-gray-300';
    case 'gold':
      return 'text-yellow-250';
    default:
      return 'text-gray-600';
  }
};

/**
 *
 * @param rewards
 * @param type
 * @param marginTop
 * @constructor
 */
export const RewardCounter = ({
  rewards,
  type,
  marginTop,
}: {
  rewards: Reward[];
  type: keyof typeof rewardTypeTextClassnames;
  marginTop?: string | number;
}) => {
  const activeRewardCounter = useMemo(
    () => rewards.filter(elt => elt.type === type).length,
    [rewards, type],
  );

  return (
    <div
      style={{ marginTop }}
      className={classNames(rewardTypeTextClassnames[type], 'font-bold text-center')}
    >
      {(type === 'copper' && <IconMedalBronze />) ||
        (type === 'silver' && <IconMedalSilver />) ||
        (type === 'gold' && <IconMedalGold />)}
      <span className={classNames('text-xs font-bold', rewardColor(type))}>
        {activeRewardCounter}
      </span>
    </div>
  );
};

interface Props {
  profile: Profile;
  className?: string;
}

export const ProfilePodiumRewards: FC<Props> = memo(function ProfilePodiumRewards(props) {
  const { profile, className } = props;
  const rewards = profile?.meta?.rewards ?? [];
  return (
    <div className={classNames('flex justify-center space-x-2 text-base my-2', className)}>
      <RewardCounter marginTop="6px" rewards={rewards as Reward[]} type="silver" />
      <RewardCounter marginTop={0} rewards={rewards as Reward[]} type="gold" />
      <RewardCounter marginTop="8px" rewards={rewards as Reward[]} type="copper" />
    </div>
  );
});
