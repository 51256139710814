import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { QuickSearch } from '../../../../front/src/components/QuickSearch/QuickSearch';
import { defaultHeaderHeight, useHeaderHeight } from '../../../../front/src/hooks/useHeaderHeight';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import type { PageConfig } from '../../../../front/src/types/debate-types';
import { isBrowser } from '../../../../front/src/utils/web';
import { HeaderLogin } from './HeaderLogin';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMenu } from './HeaderMenu';

interface Props {
  dynamicStickyHeaderHeight: number;
  pageConfig: PageConfig | undefined;
}

const HeaderNavbar: FC<Props> = memo(function HeaderNavbar(props) {
  const { dynamicStickyHeaderHeight, pageConfig } = props;
  const { setHeight } = useHeaderHeight();
  const { authUserId, isAuthDone } = usePermissions();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(ref.current?.offsetHeight ?? defaultHeaderHeight);
  }, [setHeight]);

  const shouldDisplayHeaderMenu = isAuthDone && isBrowser && authUserId;
  const shouldDisplayLoginButton = isAuthDone && (!isBrowser || !authUserId);

  return (
    <header
      ref={ref}
      id="NavbarHeader"
      // here z-index:9 is just one less than here:
      // cf: packages/front/src/styles/_layout.scss
      //  .block.is-sticky.locate-header {
      //    z-index: 10;
      //  }
      className="navbarSticky py-[15px] sm:py-[21px] sticky z-[11] bg-gradient-to-r from-[#F2F9FB] to-[#F3F2FB] flex justify-center px-[18px]"
      style={{ top: dynamicStickyHeaderHeight }}
    >
      <div className="NavbarHeader_content flex-1 flex justify-between items-center max-w-[1080px] margin-auto relative">
        <div className="NavbarHeader_logoAndSearch flex flex-1">
          <div
            className={twMerge(
              'NavbarHeader_logoContainer flex items-center justify-center _md:border-r _md:border-gray-250 _md:pr-4',
            )}
          >
            <HeaderLogo pageConfig={pageConfig} />
          </div>

          {/* In v2, h-10 was added. Add it here too? */}
          <div className="NavbarHeader_searchContainer md:flex-1 ml-8 _md:ml-2 flex justify-start items-center max-w-[600px] h-10">
            <QuickSearch />
          </div>
        </div>

        <div className="NavbarHeader_connectContainer flex justify-end">
          {shouldDisplayHeaderMenu && <HeaderMenu />}
          {shouldDisplayLoginButton && <HeaderLogin />}
        </div>
      </div>
    </header>
  );
});

export default HeaderNavbar;
