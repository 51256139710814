import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Button } from '../../../components/Button/Button';
import { Tooltip } from '../../../components/GraphModal/Tooltip';
import { IconSendMessage } from '../../../components/icons/Icons';
import { useAppApolloClient } from '../../../hooks/useAppApolloClient';
import { useAuthProfile, usePermissions } from '../../../hooks/usePermissions';
import { ReactComponent as RemoveIcon } from '../../../icons/icon-remove.inline.svg';
import { useAppDispatch } from '../../../redux/hooks';
import { openChatWindow } from '../../../redux/slices/chat-service';
import type { Maybe, Profile, Relation } from '../../../types/api';
import type { RequestFriendCallback } from '../../api/relation';
import { requestFriend, updateFriendStatus } from '../../api/relation';
import { AcceptRefuseInvitation } from './AcceptRefuseInvitation';
import { AddFriend } from './AddFriend';

const messages = defineMessages({
  startChatting: { defaultMessage: 'Envoyer un message', id: 'xh0Ygd' },
  pendingAuthorFriendRequest: { defaultMessage: 'Invitation envoyée en attente', id: 'daYWwa' },
  pendingGuestFriendRequest: { defaultMessage: 'Invitation reçue en attente', id: 'oC3vL4' },
  removeFriend: { defaultMessage: 'Supprimer de mes amis', id: 'ZjjPI1' },
  removeFriendConfirm: {
    defaultMessage: 'Etes-vous sûr de vouloir retirer { pseudo } de vos amis',
    id: 'YG7hAo',
  },
});

type ProfileRelationActionsProps = {
  currentUserId: string;
  relationId: string;
  status: string;
  appendToBody?: boolean;
  placement?: string;
  profile: Profile | null;
  relation: Maybe<Relation>;
  hideRemoveFriendButton?: boolean;
  inviteCallback?: RequestFriendCallback;
  handleClose?: () => void;
};
/**
 *
 * @param authedUserId
 * @param relationId
 * @param appendToBody
 * @param placement
 * @param props
 * @constructor
 */
export const ProfileRelationActions = ({
  relationId,
  currentUserId,
  appendToBody = false,
  placement,
  profile,
  relation,
  hideRemoveFriendButton = false,
  inviteCallback,
  handleClose,
  ...props
}: ProfileRelationActionsProps) => {
  const status = props.status;
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const client = useAppApolloClient();
  const { authUserId } = usePermissions();
  const {
    profile: { id: authProfileId = '' },
  } = useAuthProfile();
  const { pseudo, id: profileId = '' } = profile || {};
  const guestId = relation?.guest?.id;

  const startChatting = useCallback(() => {
    handleClose?.();
    openChatWindow(client, { userId: currentUserId, authUserId })(dispatch);
  }, [handleClose, client, currentUserId, authUserId, dispatch]);

  const removeFriendFn = useCallback(async () => {
    const confirmation =
      typeof window !== undefined &&
      // @TODO implement custom alert
      // eslint-disable-next-line no-alert
      window.confirm(formatMessage(messages.removeFriendConfirm, { pseudo }) as string);

    if (!confirmation) return;

    const resp = await updateFriendStatus({ relationId, profileId }, client, 'refused');
    inviteCallback?.(resp);
  }, [formatMessage, pseudo, relationId, profileId, client, inviteCallback]);

  const addFriendFn = useCallback(
    message =>
      requestFriend(
        {
          userId: currentUserId,
          profileId,
          message,
          authUserId,
          authProfileId,
        },
        client,
      ),
    [client, currentUserId, authUserId, authProfileId, profileId],
  );

  if (!profile || !authUserId || authUserId === currentUserId) return null;

  const isAuthedUserRelationGuest = authUserId === guestId;

  switch (status) {
    case 'approved':
      return (
        <div className="flex items-center gap-x-0.5">
          <Tooltip content={formatMessage(messages.startChatting)}>
            <Button variant="link" onClick={startChatting} className="no-underline px-0 flex-1">
              <IconSendMessage className="h-6 w-6 fill-gray-500 hover:fill-gray-dark" />
            </Button>
          </Tooltip>

          {!hideRemoveFriendButton && (
            <Tooltip content={formatMessage(messages.removeFriend)}>
              <Button variant="link" onClick={removeFriendFn} className="no-underline px-0 flex-1">
                <RemoveIcon
                  className="fill-gray-500 hover:fill-gray-dark"
                  style={{
                    width: '1em',
                    height: '1em',
                    transform: 'translateY(-3%)',
                  }}
                />
              </Button>
            </Tooltip>
          )}
        </div>
      );

    case 'pending':
      return (
        <span className="prose-caption text-gray-medium">
          {isAuthedUserRelationGuest ? (
            <AcceptRefuseInvitation
              isPending={true}
              relation={relation}
              authUserId={authUserId}
              callback={inviteCallback}
            />
          ) : (
            formatMessage(messages.pendingAuthorFriendRequest)
          )}
        </span>
      );

    default:
      return (
        <AddFriend
          onAddFriend={addFriendFn}
          userId={currentUserId}
          appendToBody={appendToBody}
          placement={placement}
          inviteCallback={inviteCallback}
        />
      );
  }
};
