import classNames from 'classnames';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ReactComponent as IconRefresh } from '../icons/icon-refresh.svg';
import { Tooltip } from './GraphModal/Tooltip';

const messages = defineMessages({
  refresh: { defaultMessage: 'Actualiser', id: '6aOCWK' },
});

interface Props {
  refreshAction: VoidFunction;
  className?: string;
  iconContainerClassName?: string;
  /** unset if `null`, defaults to 'items-center' if undefined (legacy). */
  verticalAlign?: 'items-center' | 'items-baseline' | null;
}

export const RefreshButton: FC<Props> = memo(function RefreshButton(props) {
  let { className, refreshAction, iconContainerClassName, verticalAlign } = props;
  if (verticalAlign === undefined) verticalAlign = 'items-center';
  const refresh = useCallback(() => refreshAction(), [refreshAction]);
  return (
    <Tooltip content={<FormattedMessage {...messages.refresh} />}>
      <button type="button" onClick={refresh} className={className}>
        <div className={classNames('flex gap-1', verticalAlign)}>
          <span className={classNames('scale-75', iconContainerClassName)}>
            <IconRefresh />
          </span>
        </div>
      </button>
    </Tooltip>
  );
});
