module.exports = {
  apiUrl: 'https://graphcomment.com',
  wsUrl: 'wss://graphcomment.com/websocket',
  imageUrl: 'https://graphcomment.com',
  graphUrl: 'https://graphcomment.com/api/graphql',
  yakliUrl: 'https://yak.li/',
  WITH_TIMER: false,
  VERBOSE: false,
  IS_DEV: false,
};
