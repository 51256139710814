import classNames from 'classnames';
import type { ReactChild, ReactFragment, ReactNode, ReactPortal } from 'react';
import React, { Children, cloneElement, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { IfInViewPort } from '../../../components/IfInViewPort';
import {
  editorJsOutputDataToHtml,
  getVersion,
  isEditorJSSerialized,
  limitEditorJsonContent,
  unserializeEditorDbEntry,
} from '../../../editor/editorjs/editorjs-utils';
import { useWebsite } from '../../../hooks/useWebsite';
import { html } from '../../../utils/dom';
import { getEmbedsEnabled, getNbImagesMax } from '../../../utils/embed';
import { type SerializedValue } from '../../ThreadMessage/useSerializeValue';
import type { PreviewRegistrationRef } from './Embed/usePreviewRegistration';
import type { LimiterContextValue } from './formatters/ElementLimiter';
import { generateTreeElementOrder, LimiterContext } from './formatters/ElementLimiter';
import { embedOrImageFormatter } from './formatters/embedFormatter';
import { emojiFormatter } from './formatters/emojiFormatter';
import { generateHighlightWordFormatter } from './formatters/highlightFormatter';
import { removeLinkFormatter } from './formatters/linkFormatter';
import { rawHtmlFormatter } from './formatters/rawHtmlFormatter';
import { recursiveFormatElement } from './formatters/recursiveElementFormatter';
import { isEditorjsContent } from './message-utils';
import styles from './MessageContent.module.scss';

export type RawMessageContentProps = {
  content?: SerializedValue;
  afterContent?: ReactNode;
  wordsToHighlight?: string;
  className?: string;
  embeds?: string;
  withMedia?: boolean;
  charsLimit?: number;
};

const { Provider: LimiterContextProvider } = LimiterContext;

const refusedMessages = defineMessages({
  deleted_by_user: { defaultMessage: "Ce message a été retiré par l'utilisateur.", id: 'Aa6K6c' },
  'message deleted': { defaultMessage: 'Ce message a été retiré par le modérateur.', id: 'nUOvys' },
  deleted: { defaultMessage: 'Ce message a été retiré', id: '/rZX8U' },
});

const isCallable = (value: unknown): value is React.ReactElement => typeof value === 'function';

const RawMessageContent: React.FC<RawMessageContentProps> = ({
  content,
  afterContent,
  wordsToHighlight,
  className,
  embeds,
  withMedia,
  charsLimit,
  ...props
}) => {
  const website = useWebsite();
  const shouldDisplayLink = website?.graphdebate?.edition?.link;

  const [shallRenderEmbed, setShallRenderEmbed] = useState(false);
  const [typeElementMap, setTypeElementMap] = useState({});

  const dbValueUnserialized = useMemo<SerializedValue>(
    () =>
      isEditorJSSerialized(content)
        ? content
        : unserializeEditorDbEntry(content, embeds) || content,
    [content, embeds],
  );

  const isEditorjs = isEditorJSSerialized(dbValueUnserialized);

  const version = getVersion(dbValueUnserialized);
  const isEjs = isEditorjsContent(version);

  const contentStr = useMemo(() => {
    return (
      (isEditorjs
        ? editorJsOutputDataToHtml(limitEditorJsonContent(dbValueUnserialized.json, charsLimit))
        : dbValueUnserialized?.substring(0, charsLimit)) || ''
    );
  }, [charsLimit, dbValueUnserialized, isEditorjs]);

  const blocks = isEditorjs && dbValueUnserialized?.json.blocks;
  const isContentOnlyOneEmbed = blocks && blocks?.length === 1 && blocks[0].type === 'linkTool';

  const displayEmbed = useCallback(() => setShallRenderEmbed(true), [setShallRenderEmbed]);
  const contentElement = useMemo(
    () => html(isEditorjs ? contentStr : rawHtmlFormatter(contentStr)),
    [contentStr, isEditorjs],
  );

  const { result: contentToDisplay, order } = useMemo(() => {
    const { order: generatedOrder } = generateTreeElementOrder();

    const buildContent = () => {
      if (!afterContent) {
        return <>{contentElement}</>;
      }
      if (Array.isArray(contentElement)) {
        const arrayChildren = Children.toArray(contentElement);
        return (
          <>
            {Children.map(arrayChildren, (child, index) => {
              const isLast = index === arrayChildren.length - 1;
              if (isLast) {
                return makeInline(child as any);
              }

              return child;
            })}
          </>
        );
      }

      return makeInline(contentElement);
    };

    // @ts-ignore
    const result = recursiveFormatElement({
      element: buildContent(),
      // @ts-ignore
      format: (str: React.ReactNode) =>
        [
          wordsToHighlight ? generateHighlightWordFormatter(wordsToHighlight) : null,
          !isEjs ? emojiFormatter : null,
          withMedia ? embedOrImageFormatter(shallRenderEmbed, version) : null,
          shouldDisplayLink ? null : removeLinkFormatter,
        ]
          .filter(isCallable)
          .reduceRight(
            (prev, fn) => (typeof fn === 'function' ? (fn(prev as string) as string) : prev),
            str,
          ),
    });

    return {
      result,
      order: generatedOrder,
    };
  }, [
    afterContent,
    contentElement,
    wordsToHighlight,
    isEjs,
    withMedia,
    shallRenderEmbed,
    version,
    shouldDisplayLink,
  ]);

  const updateType = useCallback(
    ({ id, type }) => setTypeElementMap(previousMap => ({ ...previousMap, [id]: type })),
    [],
  );
  const nbImagesMax = getNbImagesMax(website);
  const isEmbedEnabled = getEmbedsEnabled(website);

  const registeredEmbedsRef = useRef<PreviewRegistrationRef[]>([]);
  const registeredPreviewsRef = useRef<PreviewRegistrationRef[]>([]);

  const limiterValue = useMemo(
    // I think most of those values are not used anymore. To clean later.
    () =>
      ({
        order,
        updateType,
        typeElementMap,
        typeLimitMap: {
          oldImage: nbImagesMax,
          newImage: nbImagesMax,
          embed: isEmbedEnabled ? 1 : 0,
        },
        nbImagesMax,
        isEmbedEnabled,
        registeredEmbeds: registeredEmbedsRef.current,
        registeredPreviews: registeredPreviewsRef.current,
      } as LimiterContextValue),
    [isEmbedEnabled, nbImagesMax, order, typeElementMap, updateType],
  );

  return (
    <>
      {withMedia && (
        <IfInViewPort rootMargin="0px 0px 300px 0px" whenVisible={displayEmbed} triggerOnce />
      )}

      <div
        className={classNames(
          'MessageContent',
          styles.break,
          'text-gray-darkv4 leading-6 font-normal mb-2',
          className,
          isEjs && 'prose-editor',
          isContentOnlyOneEmbed && 'link-highlight',
        )}
        // style={{ lineHeight: '24px' }}
        {...props}
      >
        {withMedia ? (
          <LimiterContextProvider value={limiterValue}>
            <>
              {contentToDisplay}
              {afterContent}
            </>
          </LimiterContextProvider>
        ) : (
          <>
            {contentToDisplay}
            {afterContent}
          </>
        )}
      </div>
    </>
  );
};

/**
 *
 * @param status
 * @param content
 * @param afterContent
 * @constructor
 */
export const MessageContent = ({
  status,
  content,
  ...props
}: { status?: string; afterContent?: ReactNode } & RawMessageContentProps) => {
  if (status === 'refused' || status === 'deleted') {
    return (
      <FormattedMessage
        {...(refusedMessages[content as 'message deleted'] || refusedMessages.deleted)}
      />
    );
  }

  return <RawMessageContent content={content} {...props} />;
};

function makeInline(eltOrStr: string | JSX.Element | ReactChild | ReactFragment | ReactPortal) {
  if (!eltOrStr) return <></>;
  if (typeof eltOrStr !== 'string') {
    return cloneElement(eltOrStr as React.ReactElement, {
      // @ts-ignore
      ...eltOrStr.props,
      // @ts-ignore
      className: classNames(eltOrStr.props?.className ?? '', 'inline'),
    });
  }

  return <p className="inline">{eltOrStr}</p>;
}
