import { gql } from '@apollo/client';
import type { GraphQLClient } from 'graphql-request';
import type { NextURL } from 'next/dist/server/web/next-url';
import type { NextResponse } from 'next/server';
import { useMemo } from 'react';

import { createApolloClient } from '../../../front/src/services/apollo/createApolloClient';
import { isGqlError, logGqlError } from '../../../front/src/services/graphql/gql-utils';
import type { QueryType, Website } from '../../../front/src/types/api';
import type { AppApolloClient } from '../../../front/src/types/common-models';
import { isBrowser } from '../../../front/src/utils/fragments/isBrowser';
import { gdRoutes } from '../../../front/src/utils/gdRoutes';

// Cookie used to check on the middleware if the user is a bot, e.g. Google bot, and pass the info to React components in the browser.
export const isbCookieName = 'ISB';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

export function setCookieSerializedInMiddleware(
  response: NextResponse,
  name: string,
  value: Primitive | object,
) {
  // Sanitize input to prevent potential security issues
  const sanitizedName = encodeURIComponent(name);
  const sanitizedValue = encodeURIComponent(JSON.stringify(value));

  // Construct the Set-Cookie header
  const cookieString = `${sanitizedName}=${sanitizedValue}; Path=/;`;

  // Set the cookie in the response headers
  response.headers.append('Set-Cookie', cookieString);
}

/** It reads a cookie that was set in the middleware by `setCookieSerialized`, and it unserializes it. */
export function useCookieDeserialized(name: string) {
  return useMemo(() => {
    const cookieRegex = new RegExp(`(?:^|.*;\\s*)${encodeURIComponent(name)}\\s*=\\s*([^;]*).*$`);
    try {
      return isBrowser
        ? JSON.parse(decodeURIComponent(document.cookie.replace(cookieRegex, '$1')))
        : undefined;
    } catch (error) {
      console.warn('Fails to deserialize the cookie', name, '- returning `undefined` instead.');
      return undefined;
    }
  }, [name]);
}

export function getLightApolloClientForServer(
  websiteKey: string,
  token: string | undefined,
): AppApolloClient {
  const getPublicKey = () => websiteKey;
  const getToken = async () => token || '';
  // No refresh token function passed. No need here.
  return createApolloClient({
    getPublicKey,
    getToken,
    logout: undefined,
  });
}

export const websiteIsVIPQuery = gql`
  query WebsiteIsVIPQuery {
    website {
      subscribeByTokenVip
      languages
    }
  }
`;

export async function fetchWebsiteMiddleware(gqlClient: GraphQLClient) {
  const { website } = await gqlClient.request<QueryType>(websiteIsVIPQuery);
  return website;
}

export function checkWebsiteIsVIP(website: Website | undefined) {
  return !!website?.subscribeByTokenVip;
}

export const userIsVIPQuery = gql`
  query UserIsVIPQuery {
    me {
      profile {
        isVip
        language
      }
    }
  }
`;

// Request error handling is currently included here. Ideally, we should have generic code handling errors, as we have with Apollo.
export async function fetchUserMiddleware(gqlClient: GraphQLClient) {
  const { me } = await gqlClient.request<QueryType>(userIsVIPQuery).catch(error => {
    if (!isGqlError(error)) {
      throw error;
    }

    const isAuthError = error.response.errors?.some(err => err.message === 'invalid Token');
    if (!isAuthError) {
      logGqlError(error);
    }

    return error.response.data as QueryType;
  });
  return me;
}

const topicPagePathAsRegex = gdRoutes.topic.replace('[slug]', '([^/]+)');
const slugRegex = new RegExp(`^${topicPagePathAsRegex}$`);

export function mustRedirectToYakliMessageId(url: NextURL) {
  const slug = url.pathname.match(slugRegex)?.[1];
  const isTopicPage = !!slug;

  if (!isTopicPage) return undefined;

  const yakliType = url.searchParams.get('yakli_type');
  const yakli = url.searchParams.get('yakli');
  const commentId = url.searchParams.get('comment_id');

  const messageIdYakli = yakliType === 'comment' ? yakli : commentId;
  if (!messageIdYakli) return undefined;

  const redirectToUrl = `/t/${slug}/d/${messageIdYakli}`;
  return redirectToUrl;
}
