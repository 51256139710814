import type { FC } from 'react';
import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useWebsite } from '../../../hooks/useWebsite';
import type { Profile, WebsiteLabels } from '../../../types/api';

const messages = defineMessages({
  owner: { defaultMessage: 'Admin', id: 'iHN12u' },
  moderator: { defaultMessage: 'Modérateur', id: 'kfg5HU' },
});

const defaultMinWidthInPx = {
  owner: 46, // for ADMIN
  moderator: 84, // for MODERATEUR
};

const defaultMaxWidthInPx = 100;
interface Props {
  profile: Profile | undefined;
  className?: string;
}

type WebsiteLabelKeys = Exclude<keyof WebsiteLabels, '__typename'>;

export const GradeBadge: FC<Props> = memo(function GradeBadge(props) {
  const { formatMessage } = useIntl();
  const { profile, className } = props;
  const website = useWebsite();

  let grade = profile?.meta?.grade as WebsiteLabelKeys | 'admin' | 'user';
  if (grade === 'user') {
    return null;
  }

  let gradeLabel = website.labels?.[grade];
  if (!gradeLabel) {
    if (grade === 'admin') {
      console.warn('the "admin" grade exists. Replaced with "owner" to display the badge.');
      grade = 'owner';
    }
    if (grade) {
      // reading website.labels should not be required, since the info should be already included in gradeLabel.
      // But as of writing this code, gradeLabel is null (bug), so we fallback to website.labels.
      gradeLabel = website.labels?.[grade] || formatMessage(messages[grade]);
    }
  }
  if (!gradeLabel) return null;
  return (
    <span
      className={twMerge(
        'GradeBadge bg-red-350 uppercase text-white font-bold',
        'whitespace-nowrap overflow-hidden rounded-[5px] px-[5px] py-1 pb-[3px] text-xs leading-3 text-center',
        className,
      )}
      style={{ minWidth: defaultMinWidthInPx[grade], maxWidth: defaultMaxWidthInPx }}
    >
      {gradeLabel}
    </span>
  );
});
