import type { RootState } from '../../redux/store';
import type { Message } from '../../types/api';
import { getMessagesSortedByRelevance } from './thread-service';
import type { MessageId, ReduxMessage, ThreadState } from './thread-slice';

export function getStateMsg(state: RootState, messageId: MessageId | undefined) {
  return state.threads.messages?.[messageId || ''];
}

interface AddMessageToTreeOptions {
  yakliRootMessageId?: MessageId;
  yakliRootMessageChildren?: Set<MessageId>;
  yakliTargetMessageId?: MessageId;
  yakliFirstLoading?: boolean;
  displayDepth?: number;
  isTree?: boolean;
  debug?: boolean;
}

interface AddMessageToTreeInternal {
  selectedChildId?: MessageId;
  parentId?: MessageId;
  processed?: Set<MessageId>;
}

export function addMessagesToTree(
  messages: Message[] | undefined,
  tree: Record<MessageId, ReduxMessage>,
  position: number,
  options: AddMessageToTreeOptions = {},
  internal: AddMessageToTreeInternal = {},
) {
  const { selectedChildId, parentId, processed = new Set() } = internal;
  if (messages) {
    for (const message of messages) {
      if (message.id !== selectedChildId) {
        addMessageToTree(message, tree, position, options, { parentId, processed });
      }
    }
  }
}

export function addMessageToTree(
  message: Message | undefined,
  tree: Record<MessageId, ReduxMessage>,
  position: number,
  options: AddMessageToTreeOptions = {},
  internal: AddMessageToTreeInternal = {},
) {
  const { selectedChildId, parentId, processed = new Set() } = internal;
  const {
    yakliRootMessageId,
    yakliRootMessageChildren,
    yakliTargetMessageId,
    yakliFirstLoading,
    displayDepth,
    isTree,
  } = options;
  if (message?.id && !processed.has(message?.id) /* && !tree?.[message.id] */) {
    processed.add(message.id);
    // Is sorting optional? Is the API returning the list already sorted?
    const childMessagesSorted = getMessagesSortedByRelevance(message.childMessages);
    const childMessagesCount = message.childMessages?.meta?.count ?? null;

    // Prepare the list of child messages IDs
    let childMessagesIds: MessageId[] | null = null;
    if (childMessagesSorted) {
      addMessagesToTree(childMessagesSorted, tree, position + 1, options, {
        parentId: message.id,
        processed,
      });
      childMessagesIds = childMessagesSorted.filter(msg => msg.id).map(msg => msg.id as MessageId);
    }

    // If useful, we could also store child messages metadata here: count, page, offset, limit

    const comingFromChild = !!selectedChildId;

    // Special case: if we are on a yakli page and the current message is the last parent loaded, but the top-level message was already loaded separately (as yakli root message), the GraphQL response for the current message does not contain the parent message, but we can verify if it matches the yakli root message, and if yes, add the link on redux to render it well (e.g. to navigate in the bubble flow).
    const thisMessageIsChildOfYakliRootMessage =
      comingFromChild && yakliRootMessageChildren?.has(message.id);

    // The first time yakli messages are loaded, we give the priority to the branches that lead to the target message (`selectedChildId` below). We must be careful for that case, because the tree is filled in 2 steps. First bottom-up, from the target message to its ancestors. Then from ancestor to its children. If we set the wrong priority, it will always select the first response message instead of the branch that leads to the target message.
    // If it is the second time we load yakli messages (login/logout, hot reload in dev...), then we give the priority to the branch already set in the tree, i.e. where the user navigated (`selectedChildId` is moved after `previousSelectedChildId`). Otherwise, on refresh, the user would lose where he is and the tree would be reset to the target message.
    const previousSelectedChildId = tree[message.id]?.selectedChildId;
    const defaultSelectedChildId =
      !previousSelectedChildId &&
      yakliTargetMessageId !== message.id &&
      // position is 0-indexed, displayDepth has a shift of 1 (1 = 0 in the user settings = display root messages, not their children), so when `position` has reached `displayDepth - 1`, we should hide its children by default.
      (displayDepth == null || displayDepth - 1 > position ? childMessagesIds?.[0] : null);
    const newSelectedChildId = yakliFirstLoading
      ? selectedChildId || previousSelectedChildId || defaultSelectedChildId || null
      : previousSelectedChildId || selectedChildId || defaultSelectedChildId || null;

    tree[message.id] = {
      ...tree[message.id],
      ...message,
      position,
      childMessagesIds,
      childMessagesCount,
      parentMessageId:
        parentId ||
        message.parentMessage?.id ||
        (thisMessageIsChildOfYakliRootMessage && yakliRootMessageId) ||
        null,
      selectedChildId: newSelectedChildId,
    };

    if (!parentId && message.parentMessage) {
      addMessageToTree(message.parentMessage, tree, position - 1, options, {
        // selectedChildId is a tree-specific feature. It means: if the child is visible, it implies this is the selected child message for the parent.
        selectedChildId: isTree ? message.id : undefined,
        processed,
      });
    } else if (thisMessageIsChildOfYakliRootMessage && yakliRootMessageId) {
      // There might be cases when we want to preserve the previous selectedChildId. In that case, add a condition to do so. Because there is a case when we do NOT want it: addYakliInitialMessages first initializes the root message before the target (and other children). First init: the right selectedChildId is not known and it takes the first one. Then we initialize from the target message, going up the tree until we eventually reach the root: this time, the selectedChildId is known and should be set here.
      tree[yakliRootMessageId].selectedChildId =
        /* tree[yakliRootMessageId].selectedChildId || */ message.id;
    }
  }
  return tree[message?.id || ''] as ReduxMessage | undefined;
}

export function deleteMessageChildren(state: ThreadState, message: ReduxMessage) {
  if (!state.messages) throw new Error('[bug] Missing state.messages in doDeleteChildren');
  for (const childId of message.childMessagesIds || []) {
    const child = state.messages[childId];
    if (!child) {
      throw new Error(
        `[bug] Child message ${childId} not found in state.messages from parent ${JSON.stringify(
          message,
        )}`,
      );
    }
    deleteMessageChildren(state, child);

    delete state.messages[childId];
  }
  message.childMessagesIds = null;
}

export function assertMessageExists(
  messages: ThreadState['messages'],
  messageId: MessageId,
  message?: string,
): asserts messages {
  if (!messages) throw new Error(`[assertMessageExists] no state.messages - ${message}`);
  if (!messageId) throw new Error(`[assertMessageExists] no messageId - ${message}`);
  if (!messages[messageId])
    throw new Error(
      `[assertMessageExists] no state.messages[messageId] - messageId: ${messageId} - ${message}`,
    );
}

export function getStateMessageStrict(
  messages: Record<string, ReduxMessage> | undefined,
  messageId: MessageId | undefined | null,
  from: string,
) {
  assertMessageExists(messages, messageId!, from);
  return getStateMessage(messages, messageId)!;
}

export function getStateMessage(
  messages: Record<string, ReduxMessage> | undefined,
  messageId: MessageId | undefined | null,
) {
  const message = messageId ? messages?.[messageId] : undefined;
  return message;
}

export function openFirstBranch(
  messages: Record<string, ReduxMessage> | undefined,
  messageId: MessageId | undefined | null,
) {
  if (!messageId || !messages?.[messageId]) return;
  const message = messages[messageId];
  const childIds = message.childMessagesIds;
  if (childIds?.length && !message.selectedChildId) {
    const firstChildId = childIds[0];
    message.selectedChildId = firstChildId;
    openFirstBranch(messages, firstChildId);
  }
}
