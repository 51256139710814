import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { Link } from '../../../../components/Link';
import type { Topic } from '../../../../types/api';
import { TopicSubject } from '../../TopicSubject';

type TopicItemTitleProps = HTMLAttributes<HTMLDivElement> & {
  topic: Topic;
  isResponsive?: boolean;
  itemTitleClassName?: string;
};

const TopicItemTitle: FC<TopicItemTitleProps> = memo(function TopicItemTitle(props) {
  const { topic, className, isResponsive, itemTitleClassName = '', ...otherProps } = props;

  return (
    <div
      className={twMerge(
        'TopicItemTitle mt-[3px] text-[15px] font-semibold text-gray-soft',
        isResponsive && 'md:mt[5px] md:text-[20px]',
        className,
      )}
      {...otherProps}
    >
      <Link to="topic" params={{ slug: topic.slug }} passHref>
        <a className="hover:text-primary-base cursor-pointer">
          <h3 className={twMerge('truncate', itemTitleClassName)}>
            <TopicSubject subject={topic.subject as string} />
          </h3>
        </a>
      </Link>
    </div>
  );
});

export default TopicItemTitle;
