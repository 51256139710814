module.exports = {
    env: 'production',
    isDev: false,
    noIndex: false,
    enableCache: false,
    shouldPreventCustomHead: false,
    shouldPreventThirdParties: false,
    integrationUrl: 'https://integration.graphcomment.com/graphlogin.js',
    SPEED_UP_PARTIAL_SSR: false,
    ISR_REVALIDATE_TOKEN: '3cd4023987170fb44b4ea549b1e9b4ca244259b3e6d006b5cce4a5f83a746512'
};
