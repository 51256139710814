import classNames from 'classnames';
import type { MouseEvent, PointerEvent } from 'react';
import { forwardRef, useCallback } from 'react';

import { useRefIntercept } from '../../hooks/useRefIntercept';
import { ReactComponent as SearchIconNavbar } from '../../icons/icon-search-mobile.svg';
import { ReactComponent as SearchIconInput } from '../../icons/icon-search.inline.svg';
import { isMobile } from '../../utils/web';
import { ButtonV3Icon } from '../Button/ButtonV3Icon';
import { IconCloseV3 } from '../icons/Icons';
import type { InputPropTypes } from './Input';
import { Input } from './Input';

export interface SearchInputFieldProps extends InputPropTypes {
  onClickBack?: VoidFunction;
  showBackButton?: boolean;
  containerClassName?: string;
}

export const SearchInputField = forwardRef<HTMLInputElement, SearchInputFieldProps>(
  (props, refFn) => {
    const [refToForward, ref] = useRefIntercept(refFn);

    const { onClickBack, showBackButton, className, containerClassName, ...others } = props;

    const goBack = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation();
        const elt = ref.current;
        elt?.blur();
        onClickBack?.();
      },
      [onClickBack, ref],
    );

    const preventDefault = useCallback(
      (e: PointerEvent<HTMLButtonElement & HTMLAnchorElement>) => e.preventDefault(),
      [],
    );

    const closeButton = showBackButton && (
      <ButtonV3Icon
        className="pr-2 sm:pr-0"
        onClick={goBack}
        onPointerDown={preventDefault}
        icon={<IconCloseV3 width="12px" height="12px" />}
      />
    );

    const SearchIcon = isMobile() && !showBackButton ? SearchIconNavbar : SearchIconInput;

    return (
      <>
        <Input
          inputRef={refToForward}
          type="search"
          beforeElement={
            // The pointer-events-none is useful because packages/front/src/components/QuickSearch/QuickSearch.tsx watches clicks on the search input div and its children. But when showBackButton changes, the dom is not considered a child of the parent div any more, because it is detached from the DOM tree. To fix it, we disable the clic event on those dynamic nodes, to ensure the click is captured by a dom node (the parent) that is always present in the DOM node, i.e. remains a child of the search input top-level div.
            !showBackButton && (
              <div
                className={
                  'absolute left-0 flex h-10 w-10 sm:w-9 items-center justify-center pointer-events-none self-stretch'
                }
              >
                <SearchIcon className="w-8 h-8 md:w-[14px] md:h-[14px]" />
              </div>
            )
          }
          afterElement={closeButton}
          className={classNames(
            'bg-gray-50 flex-1',
            showBackButton ? 'px-2 placeholder:text-transparent' : 'pl-8',
            className,
          )}
          containerClassName={classNames('h-10', showBackButton && ' bg-white', containerClassName)}
          {...others}
          skipLeftPadding
          noBorder={isMobile() && !showBackButton}
          forceContainer
        />
      </>
    );
  },
);
